
.slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slider {
  width: 100%;
  height: 100%;
}

.btn-slide-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: none;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide-img i {
    position: absolute;
    font-size: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.726);
    transition: all .2s ease;
  }
  
  .btn-slide-img i:hover {
    color: #ffffff;
  }
  
  .prevImg {
    top: 45%;
    left: 80px;
    transform: translateY(-80%);
  }
  
  .nextImg {
    top: 45%;
    right: 80px;
    transform: translateY(-80%);
  }
