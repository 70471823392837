.container{
    margin: 100px 0;
}

.info {
    width: 100%;
    margin: 40px 0;
    padding:0 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description{
    width: 60%;
    margin: 40px 0;
}

.description h1{
    font-size: 2.3rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.description p{
    font-size: 1.6rem;
    text-align: left;
    letter-spacing: 1.2px;
}


.info_img{
    background-image: url('../../images/logo-info.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 200px;
    width: 30%;
}

@media screen and (max-width:768px) {
    .info_img{display: none;}

    .description{
        width: 100%;
        margin-top: 60px;
    }

}

@media screen and (max-width: 1000px) {
    .description p {
        font-size: 21pt;
        padding: 0 30px;
    }
    .description h1 {
        margin-left: 2rem;
    }
  }
@media screen and (max-width: 800px) {
  .description p {
    font-size: 16pt;
    padding: 0 20px;
  }
  .description h1 {
    margin-left: 1.2rem;
  }
}
@media screen and (max-width: 350px) {
  .description p {
    font-size: 15pt;
    padding: 0 15px;
  }
  .description h1 {
    margin-left: 1rem;
  }
}