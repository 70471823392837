.container {
  margin: 0 auto;
  margin-top: 60px;
  padding: 0 2%;
  width: 100%;
  background-color: #e5e0e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contato {
  display: grid;
  grid-template-columns: 2fr 0.1fr 2fr;
  gap: 1rem;
}

#contact_info {
  width: 30%;
}

.contato h2 {
  color: #032340;
  font-weight: normal;
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.contato p {
  margin-top: 2px;
}

.logo {
  width: 23%;
}

.mapWrapper {
  width: 25%;
  height: 150px;
  padding: 10px 0;
}

.map {
  width: 100%;
  height: 100%;
  border: 0;
}

.vl {
  border-left: 3px solid #032340;
  height: 80px;
  margin-top: 20px;
  width: 10px;
}

#social {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  color: #032340;
}

.link {
  color: #032340;
  margin-bottom: .4rem;
}

.container i {
  font-size: .9rem;
}

@media screen and (max-width: 970px) {
  .contato {
    width: 25%;
  }
  .contato p {
    font-size: .9rem;
  }

  .logo {
    width: 30%;
  }
}

@media screen and (max-width: 680px) {
  .contato {
    width: 20%;
  }
  .contato p {
    font-size: .9rem;
  }

  .logo {
    width: 35%;
  }
}

@media screen and (max-width: 570px){
    .contato, .logo, .mapWrapper{
        width:100%;
        text-align: center;
    }

    .mapWrapper{
        display: none;
    }
    .container{
        padding:10px 2%;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }

    .container i {
      font-size: .6rem;
    }
    
}

@media screen and (max-width: 400px) {
  .contato p {
    font-size: .6rem;
  }
  .contato h2 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 325px) {
  .contato{
    gap: .2rem;
  }

}