.home {
  position: relative;
  width: 100%;
  height: 770px;
  min-height: 770px;
  top: 80px;
  margin-bottom: 80px;
  z-index: -1;
}

.banner {
  width: 100%;
  height: 100%;
  background-image: url("../../images/banner-home.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #30475cee;
  opacity: 55%;
  -webkit-opacity: 55%;
  -moz-opacity: 55%;
  -o-opacity: 55%;
  -ms-opacity: 55%;
}

.content {
  max-width: 900px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  padding: 0 2%;
}

.content h1 {
  color: rgb(238, 236, 255);
  font-size: 2em;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .content h1 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 400px) {
  .content h1 {
    font-size: 1.4rem;
  }
}