@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Prompt:wght@700&display=swap');

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: sans-serif;
}

html {
  scroll-behavior: smooth;
}
 
:target {
  scroll-margin-top: 4em;
}

h1, h2, h3 {
  font-family: 'Prompt', sans-serif;
  line-height: 2.3rem;
}

p {
  font-family: 'Lato', sans-serif;
}