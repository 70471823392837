.banner{
    position: relative;
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    object-fit: fill;
    z-index: -1;
}

.banner h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	-webkit-transform: translate(-50%,-50%);
    color: rgb(238, 236, 255);
    font-size: 2.6rem;
    letter-spacing: 4px;
    z-index: 1;
}

.filter{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #30475cee;
    opacity: 50%;
    -webkit-opacity:50%;
    -moz-opacity:50%;
    -o-opacity:50%;
    -ms-opacity:50%;
}

@media screen and (max-width:570px) {
    .banner h1{
        width: 100%;
        text-align: center;
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 800px) {
    .banner{
        height: 45vh;
    }
}