.container{
    position: fixed;
    width: 100%;
    display: flex;
    justify-content:space-between;
    padding:0 2%;
    background-color: #E5E0E6;
    z-index: 3;
}

.logo{
    width: 140px;
    margin-top: 5px;
    cursor: pointer;
}

nav ul{
    display: flex;
    list-style-type: none;
}

nav li{
    padding-left: 30px;
    margin-top: 30px;
}

nav li a{
    text-decoration: none;
    color:#0C2A9F;
    font-weight: bold;
    font-size: 1.1em;
    transition: all .4s ease-in-out;
}

nav li a:hover{
    color: #0839ff;
}

.btn-mobile{
    display: none;
    font-size: 30px;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
}

.btn-mobile:hover{
    transform: scale(1.1);
    color:#0C2A9F;
    opacity: 80%;
}

.logo-navbar{
    display: none;
}

nav.nav-mobile{display: none;}


@media screen and (max-width:670px) {
    nav.nav{
        display: none;;
    }
    nav.nav-mobile{
        display: block;
        z-index: 999;
    }

    .logo-navbar{
        display: inline-block;
        width: 130px;
    }

    .nav-wrapper {
        display: none;
    }
    
    .nav-mobile ul{
        display: block;
        text-align: center;
        width: 40%;
        height: 100vh;
        position: absolute;
        top:0;
        right: 0;
        padding-top: 20px;
        background-color: #E5E0E6;
        z-index: 999;
        transition: 0.5s ease-in-out;
    }

    .nav-mobile li{
        padding-right: 10%;
    }
    .nav-mobile li {
        width: 100%;
        height: 5%;
        text-align: center;
        color: #0C2A9F;
        font-weight: bolder;
        transition: .5s;
    }
    
    .nav-mobile li a:hover{
        color: #083aff;
    }

    .btn-mobile{
        display: block;
    }
}