.contato{
    margin: 60px auto;
    max-width: 600px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.input{
    margin: 20px 0;
}

.input input, .input select{
    width: 340px;
    height: 50px;
    line-height: 50px;
    padding: 8px;
    border: 1px solid #000000;
    border-radius: 10px;
    outline: none;
    font-size: 14px;
}

.input textarea{
    width: 340px;
    resize: none;
    padding: 8px;
    border: 1px solid #000000;
    border-radius: 10px;
    outline: none;
    font-size: 14px;
}

.btn{
    width: 100px;
    margin: 20px 0;
    padding: 12px 6px;
    color: #ffffff;
    background-color: #021E42;
    border:none;
    border-radius: 10px;
    cursor: pointer;
    transition: .2s ease;
    font-size: 16px;
}

.btn:hover {
    background-color: #0c2a9f;
}

.div_checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 40px;
    border: 1px solid #000000; 
    border-radius: 10px;
}

.checkbox{
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.label_checkbox{
    padding: 0 10px;
    margin: 20px 0;
    font-size: 18px;
    cursor: pointer;
}

.div_reuniaomeet{
    display: none;
}

@media screen and (max-width: 325px){
    .input input, .input select, .input textarea{
        width: 280px;
    }
    .div_checkbox{
        width: 280px;
    }
    .label_checkbox{
        font-size: 16px;
    }
}