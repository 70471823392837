.container {
  width: 100%;
  padding: 0 2%;
  margin: 60px 0;
}

.chamada {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.chamada_barra {
  flex: 1;
  height: 3px;
  background-color: #000;
}

.chamada h1 {
  padding: 0 10px;
  font-size: 2rem;
  color: #021e42;
}

.swiper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.card {
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 70px;
  padding-bottom: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 40px;
}

.img_card{
  width:100%;
  height:300px;
  object-fit: cover;
  border-radius: 40px 40px 0 0;
}

.card h2{
  color:#021e42;
  font-weight: bolder;
  margin-top: 15px;
}

.card p{
  text-align: center;
  margin:10px 0; 
  width: 90%;
  font-weight: bolder;
}

@media screen and (max-width:900px){
  .card p{
    text-align: justify;
  }
}

@media screen and (max-width:630px) {
  .card{
    width: 80%;
    margin-left: 45px;
  }
}