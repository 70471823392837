
.slide video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.carouselButton {
  position: absolute;
  background: none;
  border: none;
  z-index: 2;
  font-size: 4rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.233);
  cursor: pointer;
  border-radius: .25rem;
  padding: 0 .5rem;
  transition: all .2s ease;
}


.container-slider {
  max-width: 100%;
  height: 45vh;
  margin: 50px auto 0;
  position: relative;
  overflow: hidden;
  border-radius: 0.75rem;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.288);
}

@media screen and (max-width: 700px){
  .container-slider {
    margin: 100px 10px 0;
  }
}

@media screen and (max-height: 900px){
  .container-slider{
    height: 55vh;
  }
}

@media screen and (max-height: 800px){
  .container-slider{
    height: 60vh;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: ease-in-out 0.4s;
}

.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: none;
  border: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide i {
  position: absolute;
  font-size: 4rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.233);
  transition: all .2s ease;
}

.btn-slide i:hover {
  color: #ffffff;
}

.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}

.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  margin: 0 10px;
  background: #ffffff;
  transition: all .3s ease;
}
.dot.active {
  background: rgb(32, 32, 32);
}

.hide {
  visibility: hidden;
}