.content{
    margin: 60px auto;
    padding:0 2%;
    max-width: 900px;
}

.content p{
    font-size:1.6rem;
    font-weight: bolder;
    text-align: left;
}

@media screen and (max-width: 1000px) {
    .content p {
        font-size: 21pt;
        padding: 0 30px;
    }
  }
@media screen and (max-width: 800px) {
  .content p {
    font-size: 16pt;
    padding: 0 20px;
  }
}
@media screen and (max-width: 350px) {
  .content p {
    font-size: 15pt;
    padding: 0 15px;
  }
}