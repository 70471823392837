.content {
  margin: 60px auto;
  padding: 0 2%;
  max-width: 900px;
  text-align: center;
}

.content p {
  font-size: 26px;
  font-weight: bolder;
  text-align: left !important;
}

.btn {
  display: inline-block;
  margin: 80px 0 10px 0;
  padding: 15px 30px;
  border: 0;
  border-radius: 10px;
  background-color: #021e42;
  color: white;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.9s;
}

.btn:hover {
  background-color: #0c2a9f;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.1em;
  transition: all .3s ease;
}

@media screen and (max-width: 1000px) {
  .content p {
    padding: 0 21pt;
  }
}
@media screen and (max-width: 800px) {
  .content p {
    font-size: 16pt;
    padding: 0 30px;
  }
}
